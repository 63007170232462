import { Box } from "@mantine/core";
import React, { memo } from "react";
import { MTypography, MWidthContainer } from "../../ui";
import classes from "./index.module.scss";

const PrivacyPolicy = () => {
  return (
    <MWidthContainer>
      <Box className={classes.root}>
        <MTypography
          variant="title_80"
          tag="h2"
          align="center"
          marginBottom={10}
        >
          Privacy Policy
        </MTypography>
        <MTypography variant="description_20" align="center" marginBottom={30}>
          Last updated on 22.02.2024
        </MTypography>
        <MTypography variant="description_16" marginBottom={16}>
          Meera Solution ('we' or 'us' or 'our') respects the privacy of our
          users ('user' or 'you'). This Privacy Policy explains how we collect,
          use, disclose, and safeguard your information when you visit our
          mobile application Wallet Management (the 'App') or our website
          walletmanagement.meerasolution.com (collectively, the 'Site').
        </MTypography>
        <MTypography variant="description_16" marginBottom={16}>
          We reserve the right to make changes to this Privacy Policy at any
          time and for any reason. We will alert you about any changes by
          updating the 'Last updated' date of this Privacy Policy. You are
          encouraged to periodically review this Privacy Policy to stay informed
          of updates. You will be deemed to have been made aware of, will be
          subject to, and will be deemed to have accepted the changes in any
          revised Privacy Policy by your continued use of the App after the date
          such revised Privacy Policy is posted.
        </MTypography>
        <MTypography variant="description_16" marginBottom={16}>
          This Privacy Policy does not apply to the third-party online/mobile
          store from which you install the App or make payments, including any
          in-game virtual items, which may also collect and use data about you.
          We are not responsible for any of the data collected by any such third
          party.
        </MTypography>
        <MTypography variant="description_16" marginBottom={16}>
          This Privacy Policy aims to provide clarity to those concerned about
          the use of their Personally Identifiable Information (PII) online.
          PII, as outlined in US privacy law and information security, refers to
          information that can identify, contact, or locate an individual,
          either on its own or when combined with other data. Please carefully
          review our Privacy Policy to understand how we collect, use, protect,
          and manage your Personally Identifiable Information on our Site.
        </MTypography>
        <MTypography variant="description_16" marginBottom={26}>
          If you do not agree with the terms of this Privacy Policy, we kindly
          ask you not to access the app.
        </MTypography>
        <MTypography variant="description_20" tag="h3" marginBottom={16}>
          What types of personal information do we collect from app users?
        </MTypography>
        <MTypography variant="description_16" marginBottom={26}>
          We collect your full name, mobile number, email address, and password
          from users of the wallet management app solely for registration and
          sign-in purposes.
        </MTypography>
        <MTypography variant="description_20" tag="h3" marginBottom={16}>
          How we utilize your information:
        </MTypography>
        <ul>
          <MTypography variant="description_16" tag="li" marginBottom={16}>
            Enhancing our website or application to provide a better user
            experience.
          </MTypography>
          <MTypography variant="description_16" tag="li" marginBottom={16}>
            Improving our customer service by better understanding your needs.
          </MTypography>
          <MTypography variant="description_16" tag="li" marginBottom={26}>
            Investigating and addressing any bugs or crashes in the application.
          </MTypography>
        </ul>
      </Box>
      <MTypography variant="description_20" tag="h3" marginBottom={16}>
        Third-party disclosure
      </MTypography>
      <MTypography variant="description_16" marginBottom={26}>
        We do not sell, trade, or share your Personally Identifiable Information
        or other Personal Information entered into our app with outside parties.
      </MTypography>
      <MTypography variant="description_20" tag="h3" marginBottom={16}>
        Third-party links
      </MTypography>
      <MTypography variant="description_16" marginBottom={26}>
        At times, we may provide links to third-party products or services on
        our Site. These third-party sites have their privacy policies. We are
        not responsible for the content or activities of these linked sites.
        However, we strive to protect the integrity of our Site and appreciate
        any feedback about these sites.
      </MTypography>
      <MTypography variant="description_20" tag="h3" marginBottom={16}>
        COPPA (Children Online Privacy Protection Act)
      </MTypography>
      <MTypography variant="description_16" marginBottom={26}>
        The Children’s Online Privacy Protection Act (COPPA) empowers parents to
        control the collection of personal information from children under the
        age of 13. The Federal Trade Commission, and the United States Consumer
        Protection Agency, enforce the COPPA Rule, which outlines what operators
        of websites and online services must do to safeguard children’s privacy
        and safety online. <br />
        We do not target our marketing efforts at children under the age of 13.
      </MTypography>
      <MTypography variant="description_20" tag="h3" marginBottom={16}>
        Amendments to Privacy Policy
      </MTypography>
      <MTypography variant="description_16" marginBottom={26}>
        We reserve the right to amend this Privacy Policy at any time, without
        prior notice to each user. Any changes we make to our Privacy Policy in
        the future will be posted here. Where appropriate, we will notify you or
        seek your consent. Please check back frequently to review any updates or
        changes to our Privacy Policy.
      </MTypography>
      <MTypography variant="description_20" tag="h3" marginBottom={16}>
        Contact Information
      </MTypography>
      <MTypography variant="description_16" marginBottom={26}>
        If you would like to share your views about our privacy practices or
        have any inquiries regarding your Personal Data (its correction,
        removal, or use), please contact us by sending a message through our
        contact form available on our website
        (walletmanagement.meerasolution.com) or on our app (wallet management)
        through our feedback/Contact Us form.
      </MTypography>
    </MWidthContainer>
  );
};

export default memo(PrivacyPolicy);
