export const COLORS = {
  primary: "#c00013",
  secondary: "#2A2C41",
  white: "#fff",
  gray: "#9fa1a4",
  black: "#000",
  gray_d5dee8: "#d5dee8",
  gradient: `linear-gradient(to right,
    #FF724C,
    #2A2C41)`,
};
