import { Box, Grid } from "@mantine/core";
import React, { memo } from "react";
import classes from "./HomeBanner.module.scss";
import { MTypography, MWidthContainer } from "../../ui";
import { IMAGES } from "../../assets/images";

const HomeBanner = () => {
  return (
    <Box>
      <MWidthContainer>
        <Box className={classes.root}>
          <Grid>
            <Grid.Col span={{ base: 12, xs: 6, md: 6, lg: 5.3 }}>
              <MTypography variant="title_80" tag="h1" marginBottom={16}>
                Financial Control & Management
              </MTypography>
              <MTypography variant="description_20" marginBottom={26}>
                Wallet management is an app that helps you to manage your money.
                Understanding your cash flow is necessary for financial control
                and management.
              </MTypography>
              <Box className={classes.googleContainer}>
                <a
                  href="https://play.google.com/store/apps/details?id=com.walletmanagement"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={IMAGES.google_play}
                    alt=""
                    className={classes.googleImg}
                  />
                </a>
              </Box>
            </Grid.Col>
            <Grid.Col span={{ base: 12, xs: 6, md: 6, lg: 6 }}>
              <Box className={classes.imageContainer}>
                <img
                  src={IMAGES.home_screen}
                  alt="portrait_black"
                  className={classes.images}
                />
              </Box>
            </Grid.Col>
          </Grid>
        </Box>
      </MWidthContainer>
      <Box className={classes.bg}>
        <Box className={classes.headingContainer}>
          <MTypography
            variant="heading_42"
            color="white"
            tag="h3"
            align="center"
          >
            "Achieve financial goals through easily tracking spending and
            managing money with just one app!"
          </MTypography>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(HomeBanner);
