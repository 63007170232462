import {
  Box,
  Button,
  Grid,
  Notification,
  TextInput,
  Textarea,
} from "@mantine/core";
import React from "react";
import { MTypography } from "../../ui";
import classes from "./index.module.scss";
import { useForm } from "@mantine/form";
import axios from "axios";
import { IMAGES } from "../../assets/images";
import { showNotification } from "@mantine/notifications";

const ContactUs = () => {
  const form = useForm({
    initialValues: {
      email: "",
      name: "",
      message: "",
    },
    validate: {
      name: (value) => (value.length < 2 ? "Required" : null),
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      message: (value) =>
        value.length < 2 ? "Message must have at least 2 letters" : null,
    },
  });
  const handleSubmit = async (data: any) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/contact`,
        data
      );
      console.log(response.data);

      showNotification({
        title: response.data.title,
        message: response.data.message,
        color: "green",
      });
      form.reset();
    } catch (error) {
      console.error(error);
      showNotification({
        title: "error",
        message: "Form Not submit",
        color: "red",
      });
    }
  };
  return (
    <Box className={classes.root}>
      <MTypography
        variant="heading_42"
        tag="h2"
        align="center"
        marginBottom={30}
      >
        Contact Us
      </MTypography>
      <Grid align="center" justify="center">
        <Grid.Col span={{ base: 12, xs: 6, md: 6, lg: 6 }}>
          <Box>
            <img
              src={IMAGES.contact_us}
              alt="contact_us"
              className={classes.image}
            />
          </Box>
        </Grid.Col>
        <Grid.Col span={{ base: 12, xs: 6, md: 6, lg: 6 }}>
          <form action="" onSubmit={form.onSubmit(handleSubmit)}>
            <Box className={classes.inputContainer}>
              <TextInput
                size="md"
                label="Name"
                placeholder="Enter Your Name"
                {...form.getInputProps("name")}
              />
            </Box>
            <Box className={classes.inputContainer}>
              <TextInput
                size="md"
                label="Email"
                placeholder="Enter Your Email"
                {...form.getInputProps("email")}
              />
            </Box>
            <Box className={classes.inputContainer}>
              <Textarea
                autosize
                label={"Message"}
                placeholder="Enter Your Message"
                minRows={4}
                maxRows={5}
                {...form.getInputProps("message")}
                radius="xs"
              />
            </Box>
            <Button type="submit" fullWidth className={classes.button}>
              <b>Submit</b>
            </Button>
          </form>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default ContactUs;
