import React from "react";

import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import "./App.css";
import { MantineProvider } from "@mantine/core";
import { RouterProvider } from "react-router-dom";
import { appRoutes } from "./router/appRoutes";
import { Notifications } from "@mantine/notifications";

function App() {
  return (
    <MantineProvider>
      <Notifications />
      <RouterProvider router={appRoutes} />
    </MantineProvider>
  );
}

export default App;
