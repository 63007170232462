import React from "react";
import HomeBanner from "../home-banner/HomeBanner";
import FeatureItem from "../feature-item/FeatureItem";
import YourOwn from "../your-own/YourOwn";

const Home = () => {
  console.log("process.env.NODE_ENV", process.env.REACT_APP_BASE_URL);

  return (
    <>
      <HomeBanner />
      <FeatureItem />
      <YourOwn />
    </>
  );
};

export default Home;
