import { createBrowserRouter } from "react-router-dom";
import TheLayout from "../container/TheLayout";
import Home from "../pages/home-page/Home";
import PrivacyPolicy from "../pages/privacy-policy/PrivacyPolicy";
import ContactUs from "../pages/contact-us/ContactUs";

export const appRoutes = createBrowserRouter([
  {
    path: "/",
    element: <TheLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/contact-us",
        element: <ContactUs />,
      },
    ],
  },
]);
