import { Box } from "@mantine/core";
import React, { FC } from "react";
import classes from "./index.module.scss";

interface IProps {
  children: React.ReactNode;
}

const MWidthContainer: FC<IProps> = ({ children }) => {
  return <Box className={classes.root}>{children}</Box>;
};

export default MWidthContainer;
