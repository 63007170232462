import React, { useState } from "react";
import classes from "./Header.module.scss";
import { Box } from "@mantine/core";
import { Link } from "react-router-dom";
import UseDevicesResize from "../../helpers/UseDevicesResize";
import { IMAGES } from "../../assets/images";

const Header = () => {
  const resize = UseDevicesResize();
  const [show, setshow] = useState(false);
  const handleClose = () => setshow(!show);
  return (
    <Box className={classes.root}>
      <Box>
        <Link to="/">
          <img src={IMAGES.logo} alt="" className={classes.logo} />
        </Link>
      </Box>

      <Box
        ml={30}
        style={{
          display: resize.width <= 690 ? (show ? "block " : " none") : "block",
        }}
      >
        <ul className={classes.ul}>
          <li>
            <Link to={"/"} onClick={handleClose} className={classes.link}>
              Home
            </Link>
          </li>
          <li>
            <Link
              to={"/privacy-policy"}
              onClick={handleClose}
              className={classes.link}
            >
              Privacy Policy
            </Link>
          </li>
          <li>
            <Link
              to={"/contact-us"}
              onClick={handleClose}
              className={classes.link}
            >
              Contact Us
            </Link>
          </li>
        </ul>
      </Box>
      <Box
        style={{
          display: resize.width <= 690 ? "block" : "none",
        }}
      >
        <button onClick={handleClose} className={classes.button}>
          <span className={classes.menu}></span>
          <span className={classes.menu}></span>
          <span className={classes.menu}></span>
        </button>
      </Box>
    </Box>
  );
};

export default Header;
