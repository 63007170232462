import React, { memo } from "react";
import classes from "./YourOwn.module.scss";
import { MTypography, MWidthContainer } from "../../ui";
import { Box, Grid } from "@mantine/core";
import { IMAGES } from "../../assets/images";

const YourOwn = () => {
  return (
    <MWidthContainer>
      <Box className={classes.root}>
        <Grid>
          <Grid.Col span={{ base: 12, sm: 6, md: 6, lg: 6 }}>
            <MTypography
              variant="description_16"
              color="primary"
              marginBottom={8}
              tag="h6"
            >
              MAKE IT YOUR OWN
            </MTypography>
            <MTypography
              variant="subHeading_32"
              marginBottom={16}
              tag="h2"
              color="secondary"
            >
              Get Control On Your Budget
            </MTypography>
            <MTypography
              variant="description_20"
              color="gray"
              marginBottom={24}
            >
              Our app Wallet Management is designed to make it easy for users to
              keep track of their spending and manage their money. Users can
              categorize their expenses, enter the amount spent, and record the
              date, helping them understand where their money is going. By
              providing this information, the app empowers users to make smarter
              decisions about their finances, ultimately helping them save money
              and stay on top of their financial goals.
            </MTypography>
            <MTypography
              variant="description_20"
              color="gray"
              marginBottom={24}
            >
              Still feeling the need for some customization? We got you covered.
              Easily change the default categories, add new ones, select the
              currency you want to use, and choose the most convenient language
              for you, all through Monefy’s intuitive interface.
            </MTypography>
          </Grid.Col>
          <Grid.Col span={{ base: 12, sm: 6, md: 6, lg: 6 }}>
            <Box className={classes.imageContainer}>
              <img
                src={IMAGES.expense_screen}
                alt=""
                className={classes.images}
              />
            </Box>
          </Grid.Col>
        </Grid>
      </Box>
    </MWidthContainer>
  );
};

export default memo(YourOwn);
