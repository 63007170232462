import logo from "./logo.png";
import portrait_black from "./portrait_black.png";
import google_play from "./google-play-badge.svg";
import footer_banner from "./footer-background.svg";
import add_category_screen from "./screen/add-category.png";
import category_screen from "./screen/category-screen.png";
import drawer from "./screen/drawer.png";
import expense_screen from "./screen/expense-screen.png";
import feedback_screen from "./screen/feedback-screen.png";
import history_screen from "./screen/history-screen.png";
import home_screen from "./screen/home-screen.png";
import contact_us from "./contact-us.svg";

export const IMAGES = {
  logo,
  portrait_black,
  google_play,
  footer_banner,
  add_category_screen,
  category_screen,
  drawer,
  expense_screen,
  feedback_screen,
  history_screen,
  home_screen,
  contact_us,
};
