import { Box } from "@mantine/core";
import React, { FC, memo } from "react";
import classes from "./index.module.scss";
import { MTypography } from "../../../ui";
import { IconType } from "react-icons";

interface IProps {
  icon: IconType;
  title: string;
  description: string;
}

const FeatureCard: FC<IProps> = ({ icon, description, title }) => {
  return (
    <Box>
      <span className={classes.icons}>{React.createElement(icon)}</span>
      <MTypography variant="subHeading_28" marginBottom={8}>
        {title}
      </MTypography>
      <MTypography variant="description_16" color="black">
        {description}
      </MTypography>
    </Box>
  );
};

export default memo(FeatureCard);
