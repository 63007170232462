import { LuSmartphone } from "react-icons/lu";
import { LiaCameraSolid } from "react-icons/lia";
import { PiGiftLight } from "react-icons/pi";
import { BsPatchCheck } from "react-icons/bs";

export const FeatureData = [
  {
    icon: LuSmartphone,
    title: "Expense Tracking",
    description: "Easily monitor your spending by categorizing transactions",
  },
  {
    icon: LiaCameraSolid,
    title: "Monthly Reports",
    description: "Download detailed summaries of your monthly spending.",
  },
  {
    icon: PiGiftLight,
    title: "Free to Use",
    description: "Access all features without any cost.",
  },
  {
    icon: BsPatchCheck,
    title: "Add Your Category",
    description: "Create personalized categories for your expenses.",
  },
];
