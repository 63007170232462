import { Box, Grid } from "@mantine/core";
import React, { memo } from "react";
import { MWidthContainer } from "../../ui";
import { IMAGES } from "../../assets/images";
import classes from "./Feature.module.scss";

import FeatureCard from "./components/FeatureCard";
import { FeatureData } from "./data/FeatureData";

const FeatureItem = () => {
  return (
    <Box className={classes.root}>
      <MWidthContainer>
        <Grid>
          <Grid.Col span={{ base: 12, sm: 12, md: 5, lg: 5 }}>
            <Box className={classes.imageContainer}>
              <img
                src={IMAGES.history_screen}
                alt=""
                className={classes.images}
              />
            </Box>
          </Grid.Col>
          <Grid.Col span={{ base: 12, sm: 12, md: 7, lg: 7 }}>
            <Grid>
              {FeatureData.map((item) => (
                <Grid.Col span={6} key={item.title}>
                  <FeatureCard
                    icon={item.icon}
                    description={item.description}
                    title={item.title}
                  />
                </Grid.Col>
              ))}
            </Grid>
          </Grid.Col>
        </Grid>
      </MWidthContainer>
    </Box>
  );
};

export default memo(FeatureItem);
